






































import { Component, Vue } from "vue-property-decorator";
import BaseCard from "../Base/BaseCard.vue";
import BaseWrapper from "../Base/BaseWrapper.vue";

@Component({
    components: {
        BaseWrapper,
        BaseCard,
    },
})
export default class LandingCompanyList extends Vue {
    public companies: any[] = [
        {
            name: "Pertamina",
            imageURL:
                "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Pertamina_Logo.svg/1200px-Pertamina_Logo.svg.png",
        },
        {
            name: "PLN",
            imageURL:
                "https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Logo_PLN.svg/1200px-Logo_PLN.svg.png",
        },
        {
            name: "PDAM",
            imageURL:
                "http://pdambangli.co.id/upload/f121d135f39f03e48da5fe5e8ced5b0a52719705920170522.jpg",
        },
        {
            name: "Google",
            imageURL:
                "https://cdn4.iconfinder.com/data/icons/google-3/1000/Google_2015_logo-512.png",
        },
        {
            name: "PLN",
            imageURL:
                "https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Logo_PLN.svg/1200px-Logo_PLN.svg.png",
        },
        {
            name: "PDAM",
            imageURL:
                "http://pdambangli.co.id/upload/f121d135f39f03e48da5fe5e8ced5b0a52719705920170522.jpg",
        },
        {
            name: "Google",
            imageURL:
                "https://cdn4.iconfinder.com/data/icons/google-3/1000/Google_2015_logo-512.png",
        },
        {
            name: "Pertamina",
            imageURL:
                "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Pertamina_Logo.svg/1200px-Pertamina_Logo.svg.png",
        },
    ];
}
